/* src/pages/Home.css */

/* Home Page Container */
.home {
    text-align: center;
    padding: 20px;
    background: #f7f9fc;
  }
  
  /* Hero Section */
  .hero {
    margin-bottom: 30px;
  }
  
  .hero h1 {
    font-size: 2.5em;
    color: #1f2d3d;
    margin-bottom: 10px;
  }
  
  .hero .subtitle {
    font-size: 1.2em;
    color: #00adb5;
    margin-bottom: 20px;
  }
  
  .hero .intro {
    font-size: 1.1em;
    color: #666;
    max-width: 100%;
    margin: 0 auto 20px;
    padding: 0 10px;
  }
  
  .cta-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.1em;
    color: #fff;
    background-color: #00adb5;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #007b8a;
  }
  
  /* Bio Section */
  .bio {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .profile-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .bio-text {
    text-align: center;
  }
  
  .bio-text h2 {
    font-size: 1.8em;
    color: #1f2d3d;
    margin-bottom: 15px;
  }
  
  .bio-text p {
    font-size: 1em;
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 2em;
    }
  
    .hero .subtitle {
      font-size: 1.1em;
    }
  
    .hero .intro {
      font-size: 1em;
    }
  
    .bio {
      padding: 15px;
    }
  
    .profile-img {
      width: 100px;
      height: 100px;
    }
  
    .bio-text h2 {
      font-size: 1.6em;
    }
  
    .bio-text p {
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 480px) {
    .hero h1 {
      font-size: 1.5em;
    }
  
    .hero .subtitle {
      font-size: 1em;
    }
  
    .hero .intro {
      font-size: 0.9em;
    }
  
    .cta-button {
      padding: 8px 16px;
      font-size: 1em;
    }
  
    .bio {
      padding: 10px;
    }
  
    .profile-img {
      width: 80px;
      height: 80px;
    }
  
    .bio-text h2 {
      font-size: 1.4em;
    }
  
    .bio-text p {
      font-size: 0.8em;
    }
  }
  
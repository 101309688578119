/* src/pages/Contact.css */

/* Contact Page Container */
.contact {
    padding: 40px 20px;
    background-color: #f7f9fc;
    text-align: center;
  }
  
  .contact h1 {
    font-size: 2.5em;
    color: #1f2d3d;
    margin-bottom: 40px;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .contact-form label {
    font-size: 1em;
    color: #1f2d3d;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .contact-form textarea {
    resize: vertical; /* Allows vertical resizing only */
  }
  
  .submit-button {
    align-self: center;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #00adb5;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #007b8a;
  }
  
  .status-message {
    margin-top: 20px;
    font-size: 1em;
    color: #00adb5;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-form {
      padding: 15px;
    }
  }
  
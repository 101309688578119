/* src/styles.css */

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

.App {
  height: 100vh;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #f7f9fc;
  color: #333;
  line-height: 1.6;
  height: 100vh;
}

header,
footer {
  background-color: #1f2d3d;
  color: #fff;
  padding: 15px 0;
  text-align: center;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 15px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

nav ul li a:hover {
  color: #00adb5;
}

main {
  padding: 40px 20px;
  max-width: 900px;
  margin: 0 auto;
}

h1,
h2,
h3 {
  color: #1f2d3d;
  margin-bottom: 20px;
}

h1 {
  font-size: 2.5em;
  text-transform: uppercase;
  border-bottom: 2px solid #00adb5;
  display: inline-block;
  padding-bottom: 10px;
}

h2 {
  font-size: 2em;
  margin-top: 40px;
}

h3 {
  font-size: 1.5em;
}

p {
  margin-bottom: 20px;
  font-size: 1.1em;
}

a {
  color: #00adb5;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #007b8a;
}

/* Sections */
section {
  margin-bottom: 60px;
}

ul {
  margin-top: 20px;
}

ul li {
  margin-bottom: 10px;
  font-size: 1.1em;
}

/* Footer */

footer p {
  margin: 10px 0;
  font-size: 1em;
}

footer div {
  margin-top: 10px;
}

footer div a {
  margin: 0 10px;
  font-size: 1.2em;
  transition: color 0.3s;
}

footer div a:hover {
  color: #00adb5;
}

/* Buttons */
button {
  background-color: #00adb5;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #007b8a;
}

/* Contact Form */
form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
}

input,
textarea {
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

input:focus,
textarea:focus {
  border-color: #00adb5;
  outline: none;
}

/* Media Queries */
@media (max-width: 768px) {
  nav ul li {
    display: block;
    margin: 10px 0;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  main {
    padding: 20px 10px;
  }
}

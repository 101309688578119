/* src/components/Header.css */

.header {
  background-color: #1f2d3d;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.logo h1 {
  margin: 0;
  font-size: 1.8em;
}

.logo a {
  color: #fff;
  text-decoration: none;
}

.myName {
  margin: 5px;
}

.nav {
  display: flex;
  align-items: center;
}

.nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav ul li {
  margin: 0 15px;
}

.nav ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.nav ul li a:hover {
  color: #00adb5;
}

/* Hamburger Menu */
.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-toggle .bar {
  background-color: #fff;
  height: 3px;
  width: 25px;
  margin: 3px 0;
  transition: 0.3s;
}

.menu-toggle .bar.toggle {
  background-color: #00adb5;
}

@media (max-width: 768px) {
  .nav {
    display: none;
  }

  .menu-toggle {
    display: flex;
  }

  .nav.open {
    display: block;
    position: absolute;
    top: 40px; /* Adjust based on header height */
    right: 0px;
    background-color: #1f2d3d;
    width: 100%; /* Adjust as needed */
    border-radius: 5px;
    z-index: 2;
  }

  .nav.open ul {
    display: block;
  }

  .nav.open ul li {
    margin: 15px 0;
  }
}

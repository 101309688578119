/* src/pages/Projects.css */

/* Projects Page Container */
.projects {
  padding: 40px 20px;
  background-color: #f7f9fc;
  text-align: center;
}

.projects h1 {
  font-size: 2.5em;
  color: #1f2d3d;
  margin-bottom: 40px;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.project-card {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  height: 100%; /* Adjust to fit content dynamically */
  transition: transform 0.3s;
  flex-grow: 1;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.project-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures button stays at the bottom */
  padding: 20px;
  flex: 1; /* Allow content to grow */
}

.project-content h2 {
  font-size: 1.5em;
  color: #1f2d3d;
  margin: 0 0 15px; /* Remove top margin to ensure proper spacing */
}

.project-content p {
  font-size: 1em;
  color: #666;
  margin: 0 0 20px; /* Remove top margin for consistency */
  line-height: 1.6;
}

.cta-button {
  align-self: center; /* Center button horizontally */
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #00adb5;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #007b8a;
}

/* Responsive Design */
@media (max-width: 768px) {
  .projects-container {
    flex-direction: column;
    align-items: center;
  }

  .project-card {
    width: 100%;
    max-width: 500px;
    height: auto; /* Allow cards to grow with content */
  }

  .project-image {
    height: 150px;
  }
}
